
<template>
  <div id="app">
    <div v-for="(item, index) in items" :key="index">
      <el-row>
        <el-col :span="6">
          <div>{{ item.name }}</div>
          <div>{{ item.price }}</div>
        </el-col>
        <el-col :span="12">
          <el-button icon="el-icon-minus" @click="decrease(index)"></el-button>
          <span style="margin-left: 10px; margin-right: 15px;">{{ item.count }}</span>
          <el-button icon="el-icon-plus" @click="increase(index)"></el-button>
        </el-col>
      </el-row>
    </div>
    <div style="margin-top: 20px;">
      {{ list() }}
    </div>
    <h3>
      <span style="margin-right: 20px;">兌換點數</span>
      <el-button icon="el-icon-minus" @click="decreasePoint"></el-button>
      <span style="margin-left: 10px; margin-right: 15px;">{{ point }}</span>
      <el-button icon="el-icon-plus" @click="increasePoint"></el-button>
    </h3>
    <hr>
    <h3>{{ totalPrice() }}</h3>
  </div>
</template>
<script>
export default {
  data() {
    return {
      service: location.pathname.replace('/', ''),
      items: [
        {
          name: '莊園拿鐵',
          price: 70,
          count: 0
        },
        {
          name: '莊園美式',
          price: 60,
          count: 0
        }
      ],
      point: 0,
    }
  },
  methods: {
    list() {
      let emptyList = [];
      for (let i = 0; i < this.items.length; i += 1) {
        for (let e = 0; e < this.items[i].count; e += 1) {
          emptyList.push(this.items[i].price)
        }
      }
      return emptyList;
    },
    totalPrice() {
      const array = this.list().sort((a, b) => b - a);
      const discountCup = this.point / 3;
      let origin = 0;
      for (let i = 0; i < array.length; i += 1) {
        if (i <= discountCup - 1) {
          origin += (array[i] * 0.9);
        }
        else {
          origin += array[i];
        }
      }
      return origin;
    },
    increase(index) {
      this.items[index].count += 1;
    },
    decrease(index) {
      if (this.items[index].count > 0) {
        this.items[index].count -= 1;
      }
    },
    decreasePoint() {
      if (this.point >= 3) {
        this.point -= 3;
      }
    },
    increasePoint() {
      this.point += 3;
    }
  }
}
</script>