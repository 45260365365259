const Ch = {
    Login: {
        checking_page: '取得專頁資訊',
        checking_user: '驗證用戶身份',
        checking_code: '檢查驗證碼',
        awaiting_login: '即將完成註冊',
        loading: '載入中',
        title: '註冊會員',
        language: '語言',
        powerby: '會員系統由EILIS智慧互動助理提供',
        authHint: '電話號碼',
        phone: '行動電話',
        birthHint: '範例: 1995/01/08',
        birth: '出生年月日',
        email: '電子信箱',
        name: '姓名',
        send: '送出',
        alreadyMember: '您已經是粉專會員囉!',
        no_service: '會員系統不支援桌上型裝置，請用行動裝置',
        scan_code: '掃描上方行動條碼做使用',
        goto_article: '點我完成報到',
        not_open: '該店家尚未開通會員服務哦！',
        already_member: '此電話已經被註冊了哦！',
        incorrect_birth: '生日填寫有誤',
        no_name: '請輸入姓名',
        no_received: '沒有收到驗證碼？',
        send_again: '重新發送',
        invalid_phone: '請輸入電話',
        used_Phone: '輸入的推薦人電話無效',
        informat_phone: '電話號碼格式有誤',
        incorrect_code: '輸入的驗證碼不符合',
        already_re: '此電話已被註冊',
        please_input_code: '請輸入驗證碼',
        input_recommend: '輸入推薦人',
        please_input_recommend: '請輸入推薦人電話',
        taiwan: '台灣',
        korea: '韓國',
        china: '大陸',
        malaysia: '馬來西亞',
        cambodia: '柬埔寨',
        notAuth: '電話號碼未驗證',
        send_verify: '送驗證碼',
        verify: '驗證',
        error_occured: '電話驗證發生異常，請稍後再試',
        verifySuccess: '電話驗證成功',
        phoneExample: '電話號碼範例',
        checking_recommend: '檢查推薦人電話'
    },
    Store: {
        please_input: '請輸入',
        please_select: '請選擇',
        nodata: '無資料',
        login: '店家登入',
        powerby: '會員系統由 EILIS 智慧互動助理提供',
        id: '店家帳號',
        search: '查詢',
        page_name: '店家名稱',
        login_account: '登入帳號',
        warning1: '有資料漏填',
        warning2: '驗證碼錯誤',
        warning3: '請選擇門市',
        store: '門市',
        vercode: '驗證碼',
        please_ver: '請輸入驗證碼',
        please_employee: '請輸入店員編號',
        clear: '清除',
        employee: '店員編號',
        send: '送出',
        check: '確認',
        userid: '用戶編號',
        system: '系統通知',
        phone: '用戶電話',
        current: '當前點數',
        notEnough: '點數不足',
        redeem: '兌換',
        topup: '贈點',
        paid: '確認付款',
        success: '成功訊息',
        totalprice: '消費金額',
        record: '記錄',
        broadcast: '推播訊息',
        consumption: '消費',
        cancel: '取消',
        refund: '退款',
        refunded: '已退款',
        time: '時間',
        point: '點數',
        searchphone: '搜尋電話',
        amount: '金額',
        noinfo: '暫無資料',
        operate: '操作',
        keyboard: '螢幕鍵盤',
        invalid_price: '輸入的金額無效',
        back: '返回',
        logout: '登出',
        consumption_complete: '消費成功訊息',
        point_redeem: '兌點成功訊息',
        point_deposit: '贈點成功訊息',
        cancel_inform: '交易取消訊息',
        refund_complete: '退款成功訊息',
        cancel_redeem: '取消兌點訊息',
        hint1: '@ELS_ACC_POINT = 用戶剩餘點數',
        hint2: '@ELS_CHARGE = 已兌換點數\n@ELS_RESTPOINT = 用戶剩餘點數',
        hint3: '@ELS_CHARGE = 已贈送點數\n@ELS_REMAIN = 用戶剩餘點數',
        hint4: '@ELS_REMAIN = 用戶剩餘點數',
        comfirm_comsume: '再次確認消費明細',
        user_phone: '用戶電話',
        deposit_point: '贈送點數',
        no_employee_id: '請輸入店員編號',
        update_succeed: '更新用戶點數成功!',
        remain_point: '剩餘點數'
    },
    Member: {
        remark: '備註',
        info: '用戶資訊',
        store_name: '名稱',
        user_name: '名稱',
        user_email: 'Email',
        user_phone: '電話',
        page_expired: '頁面已過期',
        not_member_yet: '你目前還不是會員',
        level: '等級',
        recommend_url: '推薦網址',
        save: '儲存變更',
        upload: '上傳圖片',
        check_img: '查看圖片',
        only_once: '每位用戶僅能修改一次頭像，請再次確認是否要修改?',
        complete: '完成',
        system: '系統通知',
        no_service: '此會員卡系統不支援桌上型裝置，如有特殊需求請聯絡客服。',
        not_open: '目前尚未開放，敬請期待',
        card: '卡片',
        history: '記錄',
        shop: '商城',
        discount: '優惠',
        store: '門市',
        more: '更多',
        back: '返回',
        member_info: '會員卡',
        comsume_history: '消費兌點記錄',
        // 待更新
        user: '用戶',
        current_point: '目前點數',
        qrcode: '二維碼',
        barcode: '條碼',
        invoice: '雲端發票',
        invalid_code1: '此二維碼將在',
        invalid_code2: '秒後失效',
        invalid_code3: '此二維碼已失效，請點右方圖示重整頁面',
        invalid_code4: '此條碼將在',
        invalid_code5: '此條碼已失效，請點右方圖示重整頁面',
        powerby: '會員系統由 EILIS 智慧互動助理提供',
        // 記錄
        price: '金額',
        point: '點數',
        no_comsume: '尚無消費紀錄',
        time: '時間',
        no_data: '無資料',
        no_exchange: '尚無兌點紀錄',
        // 門市資訊
        more_info: '往左滑動',
        address: '地址',
        phone: '電話',
        no_provide: '未提供'
    }
};

export default Ch;