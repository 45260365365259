<template>
<div v-if="isShowLoading" class="bg">
  <div  class="loadingball">
    <div class="ball"></div>
    <div class="ball"></div>
    <div class="ball"></div>
    <!-- <div class="loading-txt">{{content}}</div> -->
  </div>
</div>
</template>

<script>
/* eslint-disable */
export default {
  data () {
    return {
      isShowLoading: false,
      content: 'QQ'
    }
  },
  mounted() {
    const loading = document.querySelector('.loadingball');
    // loading.classList.add('show');
  },
}
</script>

<style lang="scss" scoped>
  .bg {
    z-index: 100000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(206, 206, 206, 0.582);
  }
  .loadingball {
    // background-color: rgba(0, 0, 0, 0.276);
    z-index: 10000000000;
    opacity: 1;
    display: flex;
    // margin: 300px auto;
    position: fixed;
    top: 50%;
    left: 43%;
    // position: relative;
    // margin: 0 auto;
    // bottom: 350px;
    // left: 43%;
    transition: opacity .3s ease-in;
  }

  .loadingball.show {
    opacity: 1;
  }

  .ball {
    background-color: var(--main-color);
    border-radius: 50%;
    margin: 5px;
    height: 10px;
    width: 10px;
    animation: jump .5s ease-in infinite;
  }

  .ball:nth-of-type(2) {
    animation-delay: 0.1s;
  }

  .ball:nth-of-type(3) {
    animation-delay: 0.2s;
  }
  @keyframes jump {
    0%, 100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }
  }
</style>

