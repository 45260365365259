import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
/* eslint-disable */


const eilis = 'https://emc-lite-server.eilis-ai.com/api/v1/'
const cascade = 'https://emc-action.eilis-ai.com/'
const CryptoJS = require('crypto-js');
const accessToken = 'iAEZxGAvK1O7yhROVEq6Ig==';
// var key = "BF8KN4CM58LLBKTK7HNMGHGB3HHE8LMX";
// var iv = "HHZAKE6FH263LMD8"
var key = "XHOJaT4QqkvlsmjfuLhPlOInD9NiVCv1";
var iv = "N6lRbzhhbyHgiU66"
var keyStr = CryptoJS.enc.Utf8.parse(key)
var ivStr = CryptoJS.enc.Utf8.parse(iv)
Vue.use(Vuex);

const options = {
    iv: ivStr,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
};

const state = {
  itemList: null,
}
const getters = {
  itemList: state => state.itemList,
}
const actions = {
  // 聯發取得訂單orderToken
  getAzureToken({ commit }, contents) {
    const apiUrl = `${cascade}getOrderToken`
    const content = {
        poiId: contents.poiId,
        secretKey: contents.secretkey
      };
    var signature = CryptoJS.AES.encrypt((JSON.stringify(content)), keyStr, options).toString()
    const data = {
      content: signature,
    }
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'api-key': '175e0506314052a7d2a45ae20f6b0d257720b5f5',
      }
    }
    return axios.post(apiUrl, data, config)
      .then((res) => {
          return res;
      });
  },
  // 聯發取得全部分類
  getAzureCategories({ commit }, contents) {
    console.log(contents);
    const apiUrl = `${cascade}getCategories`
    const content = {
        shopId: contents.shopId,
        secretkey: contents.secretkey
      };
    var signature = CryptoJS.AES.encrypt((JSON.stringify(content)), keyStr, options).toString()
    const data = {
      content: signature,
    }
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'api-key': '175e0506314052a7d2a45ae20f6b0d257720b5f5',
      }
    }
    return axios.post(apiUrl, data, config)
      .then((res) => {
          return res;
      });
  },
  // 聯發取得分類所有項目
  getAzureCategoryItems({ commit }, contents) {
    const apiUrl = `${cascade}getCategoryItems`
    const content = {
        shopId: contents.shopId,
        displayCategoryId: contents.displayCategoryId,
        orderToken: contents.orderToken,
        secretkey: contents.secretkey
      };
    var signature = CryptoJS.AES.encrypt((JSON.stringify(content)), keyStr, options).toString()
    const data = {
      content: signature,
    }
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'api-key': '175e0506314052a7d2a45ae20f6b0d257720b5f5',
      }
    }
    return axios.post(apiUrl, data, config)
      .then((res) => {
          return res;
      });
  },
  // 聯發取得分類項目資訊
  getAzureCategoryItemDetail({ commit }, contents) {
    const apiUrl = `${cascade}getItemDetail`
    const content = {
        shopId: contents.shopId,
        itemId: contents.itemId,
        orderToken: contents.orderToken,
        secretkey: contents.secretkey
      };
    var signature = CryptoJS.AES.encrypt((JSON.stringify(content)), keyStr, options).toString()
    const data = {
      content: signature,
    }
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'api-key': '175e0506314052a7d2a45ae20f6b0d257720b5f5',
      }
    }
    return axios.post(apiUrl, data, config)
      .then((res) => {
          return res;
      });
  },
  // 聯發送訂單
  azureSendOnlineOrder({ commit }, contents) {
    // console.log(contents);
    // return;
    const apiUrl = `${eilis}sendOnlineOrder`
    const content = {
        accountId: contents.accountId,
        orderId: contents.orderId,
        posId: contents.posId,
        userId: contents.userId,
        name: contents.name,
        phone: contents.phone,
        payWay: contents.payWay,
        price: contents.price,
        totalPrice: contents.totalPrice,
        itemList: contents.itemList,
        bookTime: contents.bookTime,
        ban: contents.ban, // 統編
        remark: contents.remark, // 備註
        bankId: contents.bankId, // 銀行代碼
        prime: contents.prime,
        cardholder: contents.cardholder,
        callbackUrl: contents.callbackUrl
      };
    var signature = CryptoJS.AES.encrypt((JSON.stringify(content)), keyStr, options).toString()
    const data = {
      content: signature,
    }
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'api-key': '175e0506314052a7d2a45ae20f6b0d257720b5f5',
      }
    }
    return axios.post(apiUrl, data, config)
      .then((res) => {
          return res;
      });
  },
}
const mutations = {
  // storeItemList(state, { itemlist }) {
  //   console.log(itemlist);
  //   state.itemList = itemlist
  //   console.log(state.itemList);
  // },
}
export default {
    state,
    getters,
    actions,
    mutations
}