<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
/* eslint-disable */
import { mapMutations, mapGetters, mapActions } from 'vuex'

export default {
  name: 'initial',
  data () {
    return {
    }
  },
  mounted() {
    // document.getElementById('name').innerHTML = this.eilisProfile.name + '數位會員卡';
    // const root = document.documentElement; // 打通css - html
    // console.log(this.brandData);
    // if (this.brandData) {
    //   root.style.setProperty('--main-color', this.brandData.brandColor);
    // }
  },
  watch: {
    brandData: {
      handler(val) {
        console.log(val);
        const root = document.documentElement; // 打通css - html
        if (val.length !== 0) {
          this.$nextTick(() => {
            document.getElementById('name').innerHTML = val.name;
            root.style.setProperty('--main-color', val.brandColor);
          })
        } else {
          root.style.setProperty('--main-color', '#7a4242');
        }
      },
      deep:true,
      immediate: true,
    },
    brandColor: {
      handler(val) {
        console.log(val);
        const root = document.documentElement; // 打通css - html
        if (val !== '') {
          this.$nextTick(() => {
            root.style.setProperty('--main-color', val);
          })
        }
      },
      deep:true,
      immediate: true,
    }
  },
  computed: {
    ...mapGetters({
      eilisProfile: 'eilisProfile',
      brandData: 'brandData',
      brandColor: 'brandColor'
    }),
  },
}

</script>

<style lang="scss">
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #222222;
  // margin-top: 0px;
}
:root {
  --main-color: #369;
}
.cube-picker-confirm {
    color: var(--main-color);
}
// 輸入框邊框顏色
.cube-input_active:after {
  border-color: var(--main-color);
  // border-color: #fc9153
}
// .cube-scroll-nav_side > .cube-sticky > .cube-sticky-fixed {
//   width:38% !important;
//   height: 480px;
// }
.cube-dialog-btn_highlight {
  color: var(--main-color);
}
.cube-scroll-nav-bar-item {
  width: 80px;
}
.cube-sticky-ele {
  height: 40px;
}
.cube-scroll-nav-panel-title {
  height: 30px;
  background-color: white;
}
.cube-tab_active {
  color: var(--main-color);
}
.cube-scroll-nav-bar-item_active {
  color:  var(--main-color)
}
.cube-tab-bar-slider {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 20px;
  background-color: var(--main-color);
}
</style>
