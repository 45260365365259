import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
// import counter from '@/components/data/counter.json'
/* eslint-disable */


const eilis = 'https://emc-lite-server.eilis-ai.com/api/v1/'
const cascade = 'https://emc-action.eilis-ai.com/'
const CryptoJS = require('crypto-js');
const accessToken = 'iAEZxGAvK1O7yhROVEq6Ig==';
// var key = "BF8KN4CM58LLBKTK7HNMGHGB3HHE8LMX";
// var iv = "HHZAKE6FH263LMD8"
var key = "XHOJaT4QqkvlsmjfuLhPlOInD9NiVCv1";
var iv = "N6lRbzhhbyHgiU66"
var keyStr = CryptoJS.enc.Utf8.parse(key)
var ivStr = CryptoJS.enc.Utf8.parse(iv)
Vue.use(Vuex);

const options = {
    iv: ivStr,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
};

const state = {
  // 串接商
  azureShopList: [],
  azureUrl: '',
  itemDetail: [],
  shopDetail: '',
  OcardUser: {},
  // 串接商
  paymentStatus: '',
  Shop: {
    name: '',
    shopId: '',
    secretkey: '',
    orderToken: '',
    openTime: '',
    posId: '',
    poiId: '',
  },
  shopList: [],
  redeemList: [],
  couponList: [],
  levelContent: '',
  orderRecord: '',
  aisleName: '', // 指定路徑components
  brandColor: '', // 品牌主色
  tempCarrier: '', // 載具條碼
  brandData: [], // 品牌資料
  personalData: [], // 個人資料
  eilisProfile: [], // 註冊資料
  shopCart: [],
  config: [],
  info: {
    clientId: localStorage.getItem('channelId') ? localStorage.getItem('channelId') : '',
    token: '',
    phone: '',
    countryCode: '',
  },
}
const getters = {
  OcardUser: state => state.OcardUser,
  paymentStatus: state => state.paymentStatus,
  itemDetail: state => state.itemDetail,

  shopDetail:  state => state.shopDetail,
  Shop:  state => state.Shop,
  shopList: state => state.shopList,
  redeemList:  state => state.redeemList,
  couponList:  state => state.couponList,
  levelContent:  state => state.levelContent,
  orderRecord:  state => state.orderRecord,
  azureUrl: state => state.azureUrl,
  azureShopList: state => state.azureShopList,
  aisleName: state => state.aisleName,
  brandColor: state => state.brandColor,
  tempCarrier: state => state.tempCarrier,
  brandData: state => state.brandData,
  personalData: state => state.personalData,
  info: state => state.info,
  eilisProfile: state => state.eilisProfile,
  config: state => state.config,
  imCart: state => state.imCart,
  shopCart: state => state.shopCart
}
const actions = {
  // 取得卷夾
  getUserCoupons({ commit },contents) {
    const apiUrl = `${eilis}getUserCoupons`
    const content = {
        accountId: contents.accountId,
        userId: contents.userId,
        accessToken: accessToken
      };
    var signature = CryptoJS.AES.encrypt((JSON.stringify(content)), keyStr, options).toString()
    const data = {
      content: signature,
    }
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'api-key': '175e0506314052a7d2a45ae20f6b0d257720b5f5',
      }
    }
    return axios.post(apiUrl, data, config)
      .then((res) => {
          return res;
      });
  },
  // 產生兌換序號
  generateCouponNumber({ commit },contents) {
    const apiUrl = `${eilis}generateCoupon`
    const content = {
        accountId: contents.accountId,
        eventId: contents.eventId
      };
    var signature = CryptoJS.AES.encrypt((JSON.stringify(content)), keyStr, options).toString()
    const data = {
      content: signature,
    }
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'api-key': '175e0506314052a7d2a45ae20f6b0d257720b5f5',
      }
    }
    return axios.post(apiUrl, data, config)
      .then((res) => {
          return res;
      });
  },

  // 聯發 **取得訂單細項**
  getAzureOrderRecordDetail({ commit },contents) {
    const apiUrl = `${cascade}getOrderDetail`
    const content = {
        orderId: contents.orderId,
        secretKey: contents.secretKey,
      };
    var signature = CryptoJS.AES.encrypt((JSON.stringify(content)), keyStr, options).toString()
    const data = {
      content: signature,
    }
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'api-key': '175e0506314052a7d2a45ae20f6b0d257720b5f5',
      }
    }
    return axios.post(apiUrl, data, config)
      .then((res) => {
          return res;
      });
  },
  // 聯發 **取得品牌列表**
  getAzureBrandList({ commit },contents) {
    const apiUrl = `${cascade}getBrandList`
    const content = {
        accountId: contents.accountId,
        accessToken: accessToken,
      };
    var signature = CryptoJS.AES.encrypt((JSON.stringify(content)), keyStr, options).toString()
    const data = {
      content: signature,
    }
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'api-key': '175e0506314052a7d2a45ae20f6b0d257720b5f5',
      }
    }
    return axios.post(apiUrl, data, config)
      .then((res) => {
          return res;
      });
  },
  // 聯發 取得網址
  getAzureUrl({ commit },contents) {
    const apiUrl = `${cascade}getOrderLink`
    const content = {
        accountId: contents.accountId,
        accessToken: accessToken,
        brandId: contents.brandId,
        phone: contents.phone,
        posId: contents.posId,
        phone: contents.phone,
        point: contents.point,
        name: contents.name,
        birth: contents.birth,
      };
    var signature = CryptoJS.AES.encrypt((JSON.stringify(content)), keyStr, options).toString()
    const data = {
      content: signature,
      // accessToken: contents.accessToken
    }
    // console.log(signature);
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'api-key': '175e0506314052a7d2a45ae20f6b0d257720b5f5',
      }
    }
    // console.log(data);
    return axios.post(apiUrl, data, config)
      .then((res) => {
          return res;
      });
  },
  // 聯發 取得門市列表
  getAzureShopList({ commit },contents) {
    const apiUrl = `${cascade}getShopList`
    const content = {
        accountId: contents.accountId,
        accessToken: accessToken,
        brandKey: contents.brandKey,
        zips: contents.zips
      };
    var signature = CryptoJS.AES.encrypt((JSON.stringify(content)), keyStr, options).toString()
    const data = {
      content: signature,
      // accessToken: contents.accessToken
    }
    // console.log(signature);
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'api-key': '175e0506314052a7d2a45ae20f6b0d257720b5f5',
      }
    }
    // console.log(data);
    return axios.post(apiUrl, data, config)
      .then((res) => {
          return res;
      });
  },
  // 取得優惠券兌換次數
  getRedeemTimes({ commit },contents) {
    const apiUrl = `${eilis}getRedeemItems`
    const content = {
        accountId: contents.accountId,
        userId: contents.userId,
      };
    var signature = CryptoJS.AES.encrypt((JSON.stringify(content)), keyStr, options).toString()
    const data = {
      content: signature,
    }
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'api-key': '175e0506314052a7d2a45ae20f6b0d257720b5f5',
      }
    }
    return axios.post(apiUrl, data, config)
      .then((res) => {
          return res;
      });
  },
  // 兌換優惠券
  redeemCoupon({ commit },contents) {
    const apiUrl = `${eilis}redeemPoints`
    const content = {
        accountId: contents.accountId,
        clientId: contents.clientId,
        phone: contents.phone,
        type: 'redeem',
        point: contents.point,
        eventId: contents.eventId,
        coupon: contents.coupon,
        eventId: contents.eventId,
        expire: contents.expire,
        itemName: contents.itemName
      };
    var signature = CryptoJS.AES.encrypt((JSON.stringify(content)), keyStr, options).toString()
    const data = {
      content: signature,
    }
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'api-key': '175e0506314052a7d2a45ae20f6b0d257720b5f5',
      }
    }
    return axios.post(apiUrl, data, config)
      .then((res) => {
          return res;
      });
  },
  // 取得優惠券
  getCoupon({ commit },contents) {
    const apiUrl = `${eilis}getItems`
    const content = {
        accountId: contents.accountId,
      };
    var signature = CryptoJS.AES.encrypt((JSON.stringify(content)), keyStr, options).toString()
    const data = {
      content: signature,
    }
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'api-key': '175e0506314052a7d2a45ae20f6b0d257720b5f5',
      }
    }
    return axios.post(apiUrl, data, config)
      .then((res) => {
          return res;
      });
  },
  // **取得訂單**
  getOrderRecord({ commit },contents) {
    const apiUrl = `${eilis}getOrderRecord`
    const content = {
        accountId: contents.accountId,
        userId: contents.userId,
        duration: contents.duration,
        type: contents.type
      };
    var signature = CryptoJS.AES.encrypt((JSON.stringify(content)), keyStr, options).toString()
    const data = {
      content: signature,
    }
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'api-key': '175e0506314052a7d2a45ae20f6b0d257720b5f5',
      }
    }
    return axios.post(apiUrl, data, config)
      .then((res) => {
          return res;
      });
  },
  // **取得等級**
  getLevel({ commit },contents) {
    const apiUrl = `${eilis}getLevels`
    const content = {
        accountId: contents.accountId,
      };
    var signature = CryptoJS.AES.encrypt((JSON.stringify(content)), keyStr, options).toString()
    const data = {
      content: signature,
    }
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'api-key': '175e0506314052a7d2a45ae20f6b0d257720b5f5',
      }
    }
    return axios.post(apiUrl, data, config)
      .then((res) => {
          return res;
      });
  },
  // 加密
  Encrypt({ }, contents) {
    // console.log(JSON.stringify(contents));
    let options = {
        iv: ivStr,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    };
    var encryptStr = CryptoJS.AES.encrypt(JSON.stringify(contents), keyStr, options).toString();
    // console.log(encryptStr)
    return encryptStr
  },
  // 更新會員資料
  updateInfo({ commit, store }, contents) {
    const apiUrl = `${eilis}updateUser`;
    const options = {
      iv: ivStr,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    };
    const content = JSON.stringify({
      phone: contents.phone,
      name: contents.userName,
      birth: contents.userBirth,
      email: contents.email,
      profileImg: contents.profileImg,
      carrier: contents.carrier,
      userId: contents.userId
    });
    // 要加toString()
    const signature = CryptoJS.AES.encrypt(content, keyStr, options).toString()
    const data = {
      content: signature,
      accessToken: contents.access,
    };
    // console.log(signature);
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'api-key': '175e0506314052a7d2a45ae20f6b0d257720b5f5',
      }
    }
    return axios.post(apiUrl, data, config)
      .then((res) => {
        return res;
      });
  },
  // 解密
  // Decrypt({ }, contents) {
  //   // console.log(contents);
  //   let encryptedHexStr = CryptoJS.enc.Base64.parse(contents);
  //   // console.log(encryptedHexStr);
  //   let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
  //   let decrypt = CryptoJS.AES.decrypt(srcs, keyStr, {
  //       iv: ivStr,
  //       mode: CryptoJS.mode.CBC,
  //       padding: CryptoJS.pad.Pkcs7
  //   });
  //   let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
  //   // console.log(decryptedStr)
  //   return decryptedStr
  //   // .then((res) => {
  //   //   return res
  //   // })
  // },
  // 台灣簡訊驗證
  TWtextmsg({ commit, store }, contents) {
  // const code = `${Math.floor(Math.random()*(9-0+1))+0}${Math.floor(Math.random()*(9-0+1))+0}${Math.floor(Math.random()*(9-0+1))+0}${Math.floor(Math.random()*(9-0+1))+0}${Math.floor(Math.random()*(9-0+1))+0}${Math.floor(Math.random()*(9-0+1))+0}`
  const apiUrl = `https://louisa-api-server.eilis-ai.com/sendSMS`
  const data = {
    phone: contents.phone, // 起始交易日期
    message: contents.code,
    service: contents.service,
    auth: contents.gate,
  }
  return axios.post(apiUrl, data)
    .then((res) => {
      // console.log(res);
      return res;
    });
  },
  getShops({ commit },contents) {
    const apiUrl = `${eilis}getShops`
    const content = {
        accountId: contents.accountId,
        zip: contents.zip
      };
    var signature = CryptoJS.AES.encrypt((JSON.stringify(content)), keyStr, options).toString()
    const data = {
      content: signature,
      // accessToken: contents.accessToken
    }
    // console.log(signature);
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'api-key': '175e0506314052a7d2a45ae20f6b0d257720b5f5',
      }
    }
    // console.log(data);
    return axios.post(apiUrl, data, config)
      .then((res) => {
          return res;
      });
  },
  checkAccountGetClientID({ commit },contents) {
    const apiUrl = `${eilis}checkAccount`
    const content = {
        accountId: contents.accountId,
        accessToken: 'iAEZxGAvK1O7yhROVEq6Ig=='
      };
    var signature = CryptoJS.AES.encrypt((JSON.stringify(content)), keyStr, options).toString()
    const data = {
      content: signature,
      // accessToken: contents.accessToken
    }
    // console.log(signature);
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'api-key': '175e0506314052a7d2a45ae20f6b0d257720b5f5',
      }
    }
    // console.log(data);
    return axios.post(apiUrl, data, config)
      .then((res) => {
          return res;
      });
  },
  // 取得品牌資料檔 accountID
  sendCode({ commit },contents) {
    const apiUrl = `${eilis}getRegConfig`
    const content = {
        code: contents.code,
        accessToken: accessToken
      };
    var signature = CryptoJS.AES.encrypt((JSON.stringify(content)), keyStr, options).toString()
    const data = {
      content: signature,
      // accessToken: contents.accessToken
    }
    // console.log(signature);
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'api-key': '175e0506314052a7d2a45ae20f6b0d257720b5f5',
      }
    }
    // console.log(data);
    return axios.post(apiUrl, data, config)
      .then((res) => {
          return res;
      });
  },
  checkUserByPhone({ commit },contents) {
    const apiUrl = `${eilis}checkUserByPhone`
    const content = {
        accountId: contents.accountId,
        phone: contents.phone,
        clientId: contents.clientId,
        accessToken: accessToken
      };
    var signature = CryptoJS.AES.encrypt((JSON.stringify(content)), keyStr, options).toString()
    const data = {
      content: signature,
      // accessToken: contents.accessToken
    }
    // console.log(signature);
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'api-key': '175e0506314052a7d2a45ae20f6b0d257720b5f5',
      }
    }
    // console.log(data);
    return axios.post(apiUrl, data, config)
      .then((res) => {
          return res;
      });
  },
  checkUserByToken({ commit },contents) {
    // console.log(contents);
    const apiUrl = `${eilis}checkUserByToken`
    const content = {
      accountId: contents.accountId,
      service: contents.service,
      auth: contents.auth,
      token: contents.token,
      // clientId: contents.clientId,
      accessToken: accessToken
    };
    // console.log(content);
    var signature = CryptoJS.AES.encrypt((JSON.stringify(content)), keyStr, options).toString()
    const data = {
      content: signature,
      // accessToken: contents.accessToken
    }
    // console.log(signature);
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'api-key': '175e0506314052a7d2a45ae20f6b0d257720b5f5',
      }
    }
    // console.log(data);
    return axios.post(apiUrl, data, config)
      .then((res) => {
          return res;
      });
  },
  register({ commit },contents) {
    const apiUrl = `${eilis}register`
    const content = {
      accountId: contents.accountId,
      accessToken: accessToken,
      service: contents.service,
      token: contents.token,
      auth: contents.auth,
      email: contents.email,
      clientId: contents.clientId,
      phone: contents.phone,
      name: contents.name,
      birth: contents.birth, // yyyy-mm-dd
      gender: contents.gender,
      address: contents.address,
      country: contents.country,
      county: contents.county,
      district: contents.district,
      zipcode: contents.zipcode,
      shopName: contents.shopName,
      shopNo: contents.shopNo,
      remarks: contents.remarks,
      voucherCode: contents.voucherCode,
      profileImg: contents.profileImg,
      addTime: contents.addTime,
      lastOrder: contents.lastOrder,
      totalAmount: contents.totalAmount,
      frequency: contents.frequency,
      isOcardUser: contents.isOcardUser
    };
    // console.log(content);
    // return;
    var signature = CryptoJS.AES.encrypt((JSON.stringify(content)), keyStr, options).toString()
    const data = {
      content: signature,
      // accessToken: contents.accessToken
    }
    // console.log(signature);
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'api-key': '175e0506314052a7d2a45ae20f6b0d257720b5f5',
      }
    }
    console.log(data);
    return axios.post(apiUrl, data, config)
      .then((res) => {
          return res;
      });
  },
  // 解密
  Decrypt({ commit }, payload) {
      // console.log(payload);
      const originText = CryptoJS.AES.decrypt(payload, keyStr, options).toString(CryptoJS.enc.Utf8);
      // console.log(originText);
      return originText
      // commit('receivePage', JSON.parse(originText));
  },
  // 獲取品牌config
  getConfig({ commit, store }, contents) {
    const apiUrl = `${eilis}getConfig`
    const content = {
      accountId: contents.accountId,
      accessToken: accessToken
    }
    var signature = CryptoJS.AES.encrypt((JSON.stringify(content)), keyStr, options).toString()
    const data = {
        content: signature,
        // accessToken: contents.accessToken
      }
      // console.log(signature);
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'api-key': '175e0506314052a7d2a45ae20f6b0d257720b5f5',
        }
      }
    return axios.post(apiUrl, data, config)
      .then((res) => {
        return res;
      });
  },
  // 快速通關
  fastClearance({ commit, store }, contents) {
    const apiUrl = `${eilis}fastClearance`
    const content = {
      clientId: contents.clientId,
      accountId: contents.accountId,
      service: contents.service,
      phone: contents.phone,
      auth: contents.auth,
      token: contents.token,
      shopNo: contents.shopNo,
      shopName: contents.shopName,
      userId: contents.userId,
      accessToken: accessToken
    }
    var signature = CryptoJS.AES.encrypt((JSON.stringify(content)), keyStr, options).toString()
    const data = {
        content: signature,
        // accessToken: contents.accessToken
      }
      // console.log(signature);
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'api-key': '175e0506314052a7d2a45ae20f6b0d257720b5f5',
        }
      }
    return axios.post(apiUrl, data, config)
      .then((res) => {
        return res;
      });
  },
}
const mutations = {
  storeOcardUser(state, { OcardUser }) {
    console.log(OcardUser);
    state.OcardUser = OcardUser
    console.log(state.OcardUser);
  },
  updateShopContent(state, { shopDetail }) {
    // console.log(paymentStatus);
    state.shopDetail = shopDetail
    // console.log(state.paymentStatus);
  },
  updatePaymentStatus(state, { paymentStatus }) {
    // console.log(paymentStatus);
    state.paymentStatus = paymentStatus
    // console.log(state.paymentStatus);
  },
  storeitemDetail(state, { itemDetail }) {
    console.log(itemDetail);
    state.itemDetail = itemDetail
    console.log(state.itemDetail);
  },
  updateShopList(state, { shopList }) {
    console.log(shopList);
    state.shopList = shopList
    // console.log(state.azureUrl)
  },
  storeRedeemList(state, { redeemList }) {
    console.log(redeemList);
    state.redeemList = redeemList
    // console.log(state.azureUrl)
  },
  storeCouponList(state, { couponList }) {
    // console.log(couponList);
    state.couponList = couponList
    // console.log(state.azureUrl)
  },
  storeLevelContent(state, { levelContent }) {
    console.log(levelContent);
    state.levelContent = levelContent
    // console.log(state.azureUrl)
  },
  updatedOrderRecord(state, { OrderRecord }) {
    console.log(OrderRecord);
    state.orderRecord = OrderRecord.sort((a, b) => b.time - a.time);
    // console.log(state.azureUrl)
  },
  storeAzureUrl(state, { azureUrl }) {
    console.log(azureUrl);
    state.azureUrl = azureUrl
    console.log(state.azureUrl)
  },
  storeAzureShopList(state, { azureShopList }) {
    // console.log(azureShopList);
    state.azureShopList = azureShopList
    // console.log(state.azureShopList)
  },
  storeAisleName(state, { aisleName }) {
    // console.log(aisleName);
    state.aisleName = aisleName
    // console.log(state.aisleName)
  },
  storepersonal(state, { personal }) {
    // console.log(Efile);
    state.personalData = personal
  },
  storeEilisFile(state, { Efile }) {
    // console.log(Efile);
    state.eilisProfile = Efile
  },
  updateCountryCode (state, { code }) {
    state.info.countryCode = code
  },
  updatephone (state, { phone }) {
    state.info.phone = phone
  },
  updateBrandData(state, { brand }) {
    // console.log(brand);
    state.brandData = brand
  },
  updateCarrier(state, { carrier }) {
    state.tempCarrier= carrier
  },
  updateBrandColor(state, { BColor }) {
    state.brandColor= BColor
  },
  // 購物車
  updateShopCart (state, { shopCart }) {
    // console.log(shopCart);
    state.shopCart = shopCart
    // console.log(shopCart);
  },
  updateShopName(state, { ShopName }) {
    // console.log(ShopName);
    state.Shop = ShopName
  },

}
export default {
    state,
    getters,
    actions,
    mutations
}