import Vue from 'vue'
import './cube-ui'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from './store'
import App from './App.vue'
import router from './router';
import VueI18n from 'vue-i18n';
import Ch from './assets/translate/Ch';
import En from './assets/translate/En';
import Loading from './components/global/src/index'
// import {VueJsonp}  from  'vue-jsonp'

// Vue.use(VueJsonp)

Vue.use(Loading)

// Vue.use(Dialog);
// Vue.use(Select);
Vue.config.productionTip = false
Vue.use(VueI18n)
Vue.use(VueAxios, axios);

const i18n = new VueI18n({
  locale: 'zh',
  messages: {
    zh: Ch,
    en: En
  }
})

new Vue({
  render: h => h(App),
  router,
  store,
  i18n,
}).$mount('#app')
