const En = {
    Login: {
        checking_page: 'Checking page',
        checking_user: 'Identify user',
        checking_code: 'Checking vercode',
        awaiting_login: 'Register Account',
        loading: 'Loading',
        title: 'Join member',
        language: 'Language',
        powerby: 'System provided by EILIS.',
        authHint: 'Phone Number',
        phone: 'PHONE',
        birthHint: 'Example: 1995/01/08',
        birth: 'BIRTH',
        email: 'EMAIL',
        name: 'NAME',
        send: 'Submit',
        alreadyMember: 'You are already member !',
        already_member: 'This phone number is used already',
        no_service: 'Member system does not support desktop devices, please use mobile devices',
        scan_code: 'Scan the QR code above',
        goto_article: 'Back to article',
        not_open: 'This account has not yet opened membership service!',
        incorrect_birth: 'The number of birthday words is incorrectly filled out.',
        taiwan: 'Taiwan',
        korea: 'Korea',
        china: 'China',
        malaysia: 'Malaysia',
        cambodia: 'Cambodia',
        notAuth: 'Phone number not verified',
        already_re: 'Phone already registered',
        please_input_code: 'Please input verification code',
        input_recommend: 'Recommend Member',
        please_input_recommend: 'Please input phone number',
        verify: 'Verify',
        send_verify: 'Send Code',
        verifySuccess: 'valid phone number',
        no_name: 'Invalid name',
        no_received: "Didn't receive code？",
        send_again: 'Retransmit',
        informat_phone: 'Wrong phone format',
        incorrect_code: 'Incorrect verification code',
        error_occured: 'An error occured, please try again later',
        phoneExample: 'Phone Example',
        invalid_phone: 'Please input phone',
        used_Phone: 'Invalid phone number',
        checking_recommend: 'Checking recommend phone'
    },
    Store: {
        please_input: 'Please input ',
        please_select: 'Please select ',
        nodata: 'No data',
        login: 'LOG IN',
        powerby: 'System provided by EILIS',
        login_account: 'Login Account',
        id: 'ID Number',
        employee: 'Employee ID', 
        page_name: 'Account',
        store: 'Store',
        search: 'Search',
        vercode: 'Verification Code',
        please_ver: 'Please input verification code',
        please_employee: 'Please input employee number',
        warning1: 'Missing Data',
        warning2: 'Wrong Verification Code',
        warning3: 'Please select store',
        clear: 'Clear',
        send: 'Send',
        check: 'Check',
        userid: 'User ID',
        system: 'System Notification',
        phone: 'Phone Number',
        current: 'Current Pont',
        notEnough: 'This user does not have enough point',
        redeem: 'Redeem',
        topup: 'Top up',
        success: 'Success Message',
        totalprice: 'Total Price',
        record: 'Record',
        broadcast: 'Broadcast Message',
        consumption: 'Comsumption',
        cancel: 'Cancel',
        refund: 'Refund',
        refunded: 'Refunded',
        time: 'Time',
        point: 'Point(s)',
        searchphone: 'Search Phone',
        amount: 'Amount',
        noinfo: 'No Information',
        operate: 'Operate',
        keyboard: 'Keyboard',
        back: 'Back',
        logout: 'Log out',
        paid: 'Confirm Payment',
        consumption_complete: 'Consumption Complete Message',
        point_redeem: 'Point Redeem Complete Message',
        point_deposit: 'Point Deposit Complete Message',
        cancel_inform: 'Cancellation Inform Message',
        refund_complete: 'Refund Complete Message',
        cancel_redeem: 'Cancel Redeem Inform Message',
        hint1: '@ELS_ACC_POINT = User remaining points',
        hint2: '@ELS_CHARGE = Redeemed points\n@ELS_RESTPOINT = User remaining points',
        hint3: '@ELS_CHARGE = Deposited points\n@ELS_REMAIN = User remaining points',
        hint4: '@ELS_REMAIN = User remaining points',
        comfirm_comsume: 'Confirm consumption details',
        user_phone: 'User Phone',
        deposit_point: 'Deposited Points',
        invalid_price: 'Invalid price, try again',
        no_employee_id: 'Please input employee ID',
        update_succeed: 'Update points successfully! ',
        remain_point: 'Remaining points'
    },
    Member: {
        remark: 'Remark',
        info: 'Member Info',
        store_name: 'Store',
        upload: 'Upload Image',
        user_name: 'User Name',
        user_email: 'Email',
        user_phone: 'Phone Number',
        page_expired: 'Page has expired',
        not_member_yet: 'You are not currently a yet',
        level: 'Level',
        recommend_url: 'Recommend Url',
        save: 'Save',
        check_img: 'Check Image',
        only_once: 'Each user can only modify the avatar once, please confirm again if you want to modify it.',
        complete: 'Complete',
        system: 'System Notification',
        no_service: 'This membership card system does not support desktop devices. For special needs, please contact EILIS customer service.',
        not_open: 'are not yet open, stay tuned',
        card: 'Card',
        history: 'History',
        shop: 'Shop',
        discount: 'Discount',
        store: 'Store',
        more: 'More',
        back: 'Back',
        member_info: 'User Information',
        comsume_history: 'History',
        // 待更新
        user: 'User',
        current_point: 'Current Points',
        qrcode: 'QR Code',
        barcode: 'Bar Code',
        invoice: 'Invoice',
        invalid_code1: 'QR Code will be expired in',
        invalid_code2: 'seconds',
        invalid_code3: 'QR Code has expired. Please click on the right to reorganize the page.',
        invalid_code4: 'Bar Code will be expired in',
        invalid_code5: 'Bar Code has expired. Please click on the right to reorganize the page.',
        powerby: 'System provided by EILIS',
        // 記錄
        price: 'Price',
        point: 'Points',
        no_comsume: 'No consumption record yet',
        time: 'Time',
        no_data: 'No Data',
        no_exchange: 'No redemption record',
        // 門市資訊
        more_info: 'More information to the right',
        address: 'Address',
        phone: 'Phone',
        no_provide: 'Not provided'
    }
};

export default En;