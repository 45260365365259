import Vue from 'vue';
import Vuex from 'vuex';
import emc from './modules/emc';
import azure from './modules/azure';
// import register from './modules/register';


// 設定環境全域變數設計，切換開發模式與產品部署模式
// import SiteConfig from './config';

// Object.defineProperty(Vuex.Store.prototype, '$conf', { value: SiteConfig });

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        emc,
        azure
        // register
    },
});

export default store;