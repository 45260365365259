import Vue from 'vue'
import Router from 'vue-router'
// import Register from '../components/user/Register.vue'
// import initial from '@/components/user/initial.vue'
// import mainpage from '@/components/user/mainpage.vue'
// import profile from '@/components/user/profile.vue'
// import shoppingcart from '@/components/user/shoppingcart.vue'
// import landingPage from '@/components/user/landingPage.vue'
// import menu from '@/components/user/menu.vue'
import test from '@/components/user/test.vue'
// import record from '@/components/user/record.vue'
// import scoreBoard from '@/components/user/comercial.vue'
// import terms from '@/components/user/terms.vue'
// const BottomNav = () => import('@/components/bottomNav');
import Vuex from 'vuex'
Vue.use(Router)
Vue.use(Vuex)

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/Register/:service',
            name: 'Register',
            component: resolve => { require(["@/components/user/Register.vue"],resolve) }
        },
        {
            path: '/initial/:service',
            name: 'initial',
            component: resolve => { require(["@/components/user/initial.vue"],resolve) }
        },
        {
            path: '/landingpage/:service',
            name: 'landingPage',
            component: resolve => { require(["@/components/user/landingPage.vue"],resolve) }
        },
        {
            path: '/menu/:service',
            name: 'menu',
            component: resolve => { require(["@/components/user/menu.vue"],resolve) }
        },
        {
            path: '/main/:service',
            name: 'mainpage',
            component: resolve => { require(["@/components/user/mainpage.vue"],resolve) }
        },
        {
            path: '/mainbottom/:service',
            name: 'mainbottom',
            component: resolve => { require(["@/components/bottomNav"],resolve) }
        },
        {
            path: '/profile/:service',
            name: 'profile',
            component: resolve => { require(["@/components/user/profile.vue"],resolve) }
        },
        {
            path: '/shoppingcart/:service',
            name: 'shoppingcart',
            component: resolve => { require(["@/components/user/shoppingcart.vue"],resolve) }
        },
        {
            path: '/test',
            name: 'test',
            component: test
        },
        {
            path: '/record',
            name: 'record',
            component: resolve => { require(["@/components/user/record.vue"],resolve) }
        },
        {
            path: '/scoreboard',
            name: 'scoreboard',
            component: resolve => { require(["@/components/user/scoreBoard.vue"],resolve) }
        }
    ],
})

export default router;